import { apiFetch } from '@nrdy-marketing-engine/api-fetch';
import { Client } from '@nrdy-marketing-engine/cart/@types/cart-state';

const createStudent = (client: Client) =>
  apiFetch(`/vtwa/v5/clients/${client.uuid}/students`, {
    method: 'POST',
    body: JSON.stringify({
      first_name: client.firstName,
      last_name: '',
      email: client.email,
      grade_list_id: '20',
    }),
  }).then((res) => res.data);

export default createStudent;
