import { AllowedCountryCode } from '@nrdy-marketing-engine/cart/@types/cart-state';

function jurisdictionToCountryCode(jurisdictionId: number): AllowedCountryCode {
  if (jurisdictionId === 100) {
    return 'GB';
  } else if (jurisdictionId >= 94 && jurisdictionId <= 99) {
    return 'CA';
  } else {
    return 'US';
  }
}
export { jurisdictionToCountryCode };
