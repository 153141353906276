'use client';
import { Client } from '@nrdy-marketing-engine/cart/@types/cart-state';
import { UserContract } from '@nrdy-marketing-engine/cart/@types/contract';
import { Me } from '@nrdy-marketing-engine/cart/@types/me';
import { StudentDataResponse } from '@nrdy-marketing-engine/cart/@types/student-data';
import React from 'react';
import { AvailableUserCreationTypes } from './@types/available-user-creation-types';
import useUser from './hooks/use-user';

export interface UserContextInterface {
  clientID: string;
  contracts?: UserContract[];
  convertUserAndStore?: () => Promise<void>;
  createUserAndStore?: (clientParams: Client, type?: AvailableUserCreationTypes) => Promise<void>;
  loading: boolean;
  refetchUserAndStore?: () => Promise<void>;
  signInAndStore?: ({ email, password }: Partial<Client>) => Promise<any>;
  students?: StudentDataResponse;
  studentsError?: Error | null;
  user: Me;
}

export const UserCtx = React.createContext<UserContextInterface | null>({
  clientID: null,
  contracts: [],
  loading: true,
  students: null,
  studentsError: null,
  user: null,
});

export function UserProvider(props: {
  children: React.ReactNode;
  /** The userContext passed down from the server-side, if present, to skip further fetches */
  userContext?: UserContextInterface;
}) {
  const { children } = props;
  const userProps = useUser(props.userContext);

  return <UserCtx.Provider value={userProps}>{children}</UserCtx.Provider>;
}

// custom hook, main way of interaction with context
// largely for readability and making sure that you're not trying to use it without the expected provider
export const useUserContext = () => {
  const context = React.useContext(UserCtx);

  if (context === undefined) {
    throw new Error('useUserContext can only be used within an UserProvider');
  }

  return context;
};
