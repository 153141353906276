import { Client } from '../../../@types/cart-state';
import { Me } from '../../../@types/me';
import { jurisdictionToCountryCode } from '../../lib/country-code/jurisdiction-to-country-code';
import hasEnablement from './has-enablement';

export const transformMeToClient = (me: Me): Client =>
  me?.entity?.is_client && {
    clientId: me.entity.client_id,
    countryCode: jurisdictionToCountryCode(me.entity.jurisdiction_id),
    email: me.entity.email,
    firstName: me.entity.first_name,
    hasMembership: hasEnablement(me, 'active_membership'),
    id: me.entity.id,
    jurisdictionId: me.entity.jurisdiction_id || 1,
    lastName: me.entity.last_name,
    uuid: me.entity.client_uuid,
  };
